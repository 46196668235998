import React from 'react'
import Img from 'gatsby-image'
import styled, { keyframes, css } from 'styled-components'
import grainImage from './grain.png'
import { Link } from 'gatsby'
import GlobalStyle from '../GlobalStyle'

const colorAnimate = keyframes`
  0% { filter: grayscale(0%); }
  100% { filter: grayscale(100%); }
`;

const buttonAnimate = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const ActiveButton = styled.button`
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border: none;
  background: none;
  transition: opacity 2s ease;
  opacity: 0;
  cursor: pointer;
  z-index: -10;
`;

const ClickAnywhere = styled.span`
  font-family: Montserrat, sans-serif;
  background: none;
  border: none;
  align-self: flex-end;
  font-size: 3vw;
  margin-bottom: 10vh;
  width: 100%;
  padding-left: 10px;
  z-index: 5;
  color: #ffabab;
`;

const grain = keyframes`
  0%, 100% { transform:translate(0, 0) }
  10% { transform:translate(-5%, -7%) }
  20% { transform:translate(-5%, 5%) }
  30% { transform:translate(7%, -5%) }
  40% { transform:translate(-5%, 5%) }
  50% { transform:translate(-5%, 5%) }
  60% { transform:translate(5%, 0%) }
  70% { transform:translate(0%, 8%) }
  80% { transform:translate(3%, 10%) }
  90% { transform:translate(-5%, 10%) }
`;

const FullPageSection = styled.section`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black;
  display: flex;
  justify-content: center;
  :after {
    animation: ${grain} 8s steps(10) infinite;
    background-image: url(${grainImage});
    background-repeat: repeat;
    content: "";
    height: 300vh;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    top: -50%;
    width: 300vw;
  }
`
const RhombusContainer = styled.section`
  clip-path: polygon(40% 0%,100% 0%,80% 160%,0% 50%);
  max-height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw;
  display: block;
  padding: 0;
  overflow: hidden;
  z-index: 1;
  transition: filter 2s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  ${({ isLoaded }) => isLoaded && 'filter: grayscale(100%);'}
  :hover {
    ${({ clicked }) => !clicked ? 'cursor: pointer;' : ''}
    ${({ clicked }) => !clicked ? 'filter: grayscale(0%);' : ''}
    transition: filter 1s ease-in;
  }
  @media (min-aspect-ratio: 1035 / 720) {
    width: 65vw;
  }
`

const RhombusContainer2 = styled.section`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    clip-path: polygon(40% 0%,100% 0%,75% 197%,0% 63%);
    background-color: #191919;
    max-height: 100vh;
    margin: auto;
    width: 100vw;
    display: none;
    padding: 0;
    overflow: hidden;
    :hover {
      ${({ clicked }) => !clicked && 'cursor: pointer;'}
    }
    @media (max-width: 500px) { display: block; }
`

const scrollDown = keyframes`
  0% { margin-top: -100%; }
  100% { margin-top: 0%; }
`;
const StyledLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  ${({ clicked }) => clicked &&
    css`animation: ${scrollDown} 750ms ease-out;`
  }
`

const StyledLinkContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition-property: background-color;
  transition-duration: 350ms;
  transition-timing-function: ease;
  :hover {
    background-color: #5991e6;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease;
  }
  @media (max-width: 800px) {
    background-color: #5991e6;
  }
`

const StyledLink = styled(Link)`
  z-index: 10;
  letter-spacing: 5vw;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 8vw;
  text-decoration: none;
  color: #ffabab;
  display: flex;
  align-items: center;
  transition-property: filter, background-color;
  transition-duration: 250ms;
  transition-timing-function: ease;
  width: 100%;
  justify-content: center;
  filter: grayscale(100%);
  :hover {
    background-color: #5991e6b3;
    cursor: pointer;
    filter: grayscale(0%);
    transition-property: filter, background-color;
    transition-duration: 400ms;
    transition-timing-function: ease;
  }
  @media (max-width: 800px) {
    padding: 1vh 0;
    background-color: #5991e6b3;
    filter: grayscale(0%);
  }
`;

const ElleDee = styled.h1`
  font-family: Montserrat, sans-serif;
  color: #ffabab;
  z-index: 10;
  top: 3.7vh;
  left: 0;
  right: 0;
  margin: 0;
  position: absolute;
  font-size: 20vw;
  cursor: pointer;
`

export default class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: !!props.clicked,
      isLoaded: false,
    }
  }
  state = {
    clicked: false,
    isLoaded: false,
  }

  onClick = () => {
    this.setState({ clicked: true });
  }

  onLoad = () => {
    this.setState({ isLoaded: true });
  }

  render() {
    const data = this.props;
    const { clicked, isLoaded } = this.state;
    return (
      <FullPageSection onClick={this.onClick}>
        <GlobalStyle />
        <RhombusContainer2 onClick={this.onClick} clicked={clicked} />
        <RhombusContainer isLoaded={isLoaded} onClick={this.onClick} clicked={clicked}>
          <Img backgroundColor={'#FB3B59'} onLoad={this.onLoad} fluid={data.file.childImageSharp.fluid} />
        </RhombusContainer>
        {!clicked &&
          <>
            <ActiveButton onClick={this.onClick} />
            <ElleDee onClick={this.onClick}>ELLE DEE</ElleDee>
            <ClickAnywhere onClick={this.onClick}>CLICK ANYWHERE</ClickAnywhere>
          </>
        }
        {clicked &&
          <StyledLinksContainer clicked={clicked}>
            <StyledLinkContainer>
              <StyledLink to="/sounds">Sounds</StyledLink>
            </StyledLinkContainer>
            <StyledLinkContainer>
              <StyledLink to="/events">Events</StyledLink>
            </StyledLinkContainer>
            <StyledLinkContainer>
              <StyledLink to="/booking">Booking</StyledLink>
            </StyledLinkContainer>
            <StyledLinkContainer>
              <StyledLink to="/bio">About</StyledLink>
            </StyledLinkContainer>
          </StyledLinksContainer>
        }
      </FullPageSection>
    )
  }
}
